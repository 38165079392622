import "./App.css";
import Header from "./components/Header";
import Services from "./components/Services";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="relative dark">
      <img src="images/pattern1.png" className="absolute -z-10 top-96" />
      <img
        src="images/pattern2.png"
        className="absolute -z-10 bottom-80 right-0"
      />
      <img
        src="images/galaxy.png"
        className="absolute -z-10 opacity-30 top-0 w-screen h-300"
      />

      <div className="max-w-screen-xl mx-auto z-20">
        <Header />
        <Services />
        <Footer />
      </div>
      <img
        src="nasa.png"
        className="absolute -z-10 opacity-30 bottom-0 w-screen h-300"
      />
    </div>
  );
}

export default App;
