const Footer = () => {
  return (
    <footer>
      <div className="flex max-w-screen-xl w-full justify-between p-8">
        <div className="flex flex-col gap-4">
          <p className="text-2xl uppercase font-barlow font-extrabold">
            contact us
          </p>
          <div className="w-16 border-t-2"></div>
          <p>
            <span className="font-bold">
              Ubitech Australia
            </span>
            <br />
            <span>Australia</span>
            <br />
            <span className="underline">young@ubitech.net.au</span>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-2xl uppercase font-barlow font-extrabold">
            About us
          </p>
          <div className="w-16 border-t-2"></div>
          <p>Home</p>
          <p>About us</p>
          <p>Information post</p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-2xl uppercase font-barlow font-extrabold">
            follow us
          </p>
          <div className="w-16 border-t-2"></div>
          <p>Facebook</p>
          <p>Twitter</p>
          <p>Instagram</p>
          <p>Youtube</p>
        </div>
      </div>
      <p className="text-center m-6">
        Copyright©Ubitech Australia 2023. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
