const Header = () => {
  return (
    <header className="mb-10 z-20">
      <div className="flex max-w-screen-xl w-full justify-between p-8">
        <div className="flex flex-col gap-2 text-24">
          <div className="text-3xl font-medium" style={{ fontSize: "3.5rem", fontWeight: 1000 }}>
            <p1>Ubitech Australia</p1>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
