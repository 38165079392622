import Slider from "@mui/material/Slider";
import { useState } from "react";

const Services = () => {
  const [width1, setWidth1] = useState(50);
  const [width2, setWidth2] = useState(50);
  const [width3, setWidth3] = useState(50);
  return (
    <>
      <section className="grid grid-cols-2 gap-x-6 gap-y-20 items-center mb-2 relative">
        <div>
          <p2 className="font-barlow uppercase font-extrabold text-4xl">
            Cutting-edge data analytics solutions
          </p2>
          <p className="text-lg mt-10">
            Ubitech Australia is at the forefront of
            leveraging satellite technology to deliver cutting-edge data
            analytics solutions. Our suite of services empowers industries,
            researchers, and government agencies with insights derived from
            satellite imagery and remote sensing data. Whether it's monitoring
            weather patterns for agriculture, analyzing land use for urban
            planning, or tracking natural disasters for early warning systems,
            Ubitech Australia has it covered. Our advanced
            algorithms and data models sift through vast amounts of satellite
            data, converting them into actionable insights. Customers can rely
            on Ubitech Australia's precise, timely, and
            customized analytics for making informed decisions that have
            far-reaching positive impacts.
          </p>
        </div>
        <div>
          <img src="images/pngegg 1.png" alt="sky watch" className="ml-auto" />
        </div>
      </section>
      <div className="mb-10">
        <img src="images/mouse.svg" alt="sky watch" className="m-auto icon-dark" />
        <img
          src="images/mouse-light.svg"
          alt="sky watch"
          className="m-auto icon-light"
        />
      </div>
      <section className="grid grid-cols-2 gap-x-6 gap-y-20 items-center mb-36 relative">
        <div>
          <div className="image-layer-container">
            <div
              className="image-layer-item left-0"
              style={{ width: width1 + "%", zIndex: "1000" }}
            >
              <img src="images/img1.png" alt="sky watch" />
            </div>
            <div className="image-layer-item right-0">
              <img src="images/images1713355_t11.jpg" alt="sky watch" />
            </div>
          </div>
          <div className="w-[558px]">
            <Slider
              value={width1}
              onChange={(e) => setWidth1(e.target.value)}
              aria-label="Default"
              valueLabelDisplay="off"
            />
          </div>
        </div>
        <div>
          <p2 className="font-barlow uppercase font-extrabold text-4xl">
            FAST-TRACK ACCESS TO INFORMATION
          </p2>
          <div className="w-16 border-t-2 my-4"></div>
          <p className="text-lg">
            Ubitech Australia &apos;s Data Hub application is a
            powerful and innovative platform designed to provide customers with
            unparalleled access to a wealth of satellite imagery and derived
            datasets. Harnessing the capabilities of cloud computing, Al, and
            advanced analytics, Data Hub offers up-to-date satellite imagery
            from across the globe. Our future customers and researchers can rely
            on our platform for:{" "}
            <ul className="list-disc ml-7">
              <li>
                Access the latest satellite images of different satellites all
                over the world
              </li>
              <li>
                Access wide range of derived datasets includes{" "}
                <strong className="">hyper-resolution data</strong> that
                captures minute details, as well as{" "}
                <strong className="">comprehensive land use </strong>
                data which is invaluable for environmental studies and urban
                planning.
              </li>
            </ul>
          </p>
        </div>
        <div>
          <p2 className="font-barlow uppercase font-extrabold text-4xl">
            Real-time weather and catastrophic information
          </p2>
          <div className="w-16 border-t-2 my-4"></div>
          <p className="text-lg">
            Ubitech Australia &apos;s Weather and Catastrophic
            Data Analytics service is an essential resource for real-time
            monitoring and analysis of weather and natural disasters. Utilizing
            cutting-edge satellite technology, Al, and big data analytics, the
            service provides detailed weather data and forecasts essential for
            agriculture, logistics, and energy industries. Some benefits that
            customers can gain from our platform are:{" "}
            <ul className="list-disc ml-7">
              <li>Real-time weather and catastrophic events</li>
              <li>
                High-resolution imagery, predictive modeling, and a user-centric
                interface Customers are equipped with the crucial information to
                make well- informed decisions in routine and emergency
                situations.
              </li>
            </ul>
          </p>
        </div>
        <div>
          <div className="image-layer-container">
            <div
              className="image-layer-item left-0"
              style={{ width: width2 + "%", zIndex: "1000" }}
            >
              <img src="images/img2.png" alt="sky watch" />
            </div>
            <div className="image-layer-item right-0">
              <img src="images/amazon_2-2036079.jpg" alt="sky watch" />
            </div>
          </div>
          <div className="w-[558px]">
            <Slider
              value={width2}
              onChange={(e) => setWidth2(e.target.value)}
              aria-label="Default"
              valueLabelDisplay="off"
            />
          </div>
        </div>
        <div>
          <div className="image-layer-container">
            <div
              className="image-layer-item left-0"
              style={{ width: width3 + "%", zIndex: "1000" }}
            >
              <img src="images/img3.png" alt="sky watch" />
            </div>
            <div className="image-layer-item right-0">
              <img src="images/img4.png" alt="sky watch" />
            </div>
          </div>
          <div className="w-[558px]">
            <Slider
              value={width3}
              onChange={(e) => setWidth3(e.target.value)}
              aria-label="Default"
              valueLabelDisplay="off"
            />
          </div>
        </div>
        <div>
          <p2 className="font-barlow uppercase font-extrabold text-4xl">
            Realtime landuse and enivronmental information
          </p2>
          <div className="w-16 border-t-2 my-4"></div>
          <p className="text-lg">
            Ubitech Australia &apos;s Land Use and
            Environmental Data Analytics service harnesses satellite technology,
            Al, and advanced data processing to deliver intricate insights into
            land use and environmental patterns. Our service offers land use
            classification which can discern between agricultural, residential,
            and industrial land, and detailed measurements of roads and houses.
            Moreover, it provides critical environmental information including
            CO2 levels, aiding in pollution monitoring and climate change
            studies. Some benefits that customers can gain from our platform
            are:{" "}
            <ul className="list-disc ml-7">
              <li>
                Access to updated land use and environmental information all
                over the world
              </li>
              <li>
                Advanced analytics for each region, including facilities
                measuring, such as houses and roads
              </li>
              <li>
                Advanced analytics of environmental information such as CO2
                level measurement, etc.
              </li>
            </ul>
          </p>
        </div>
      </section>
    </>
  );
};

export default Services;
